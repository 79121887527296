import React, { useEffect, useState } from "react";
import Footer from ".././Footer";
import styled from "styled-components";
import Flex from "../../components/Flex";
import Traits from ".././analysis/Traits";
import Navbar from "../../components/Navbar";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import ErrorFallback from ".././ErrorFallback";
import TestDetails from ".././analysis/TestDetails";
import UserDetails from ".././analysis/UserDetails";
import { REPORT_PAGE_BOOKING_LINK } from "../../../utils/constants";
import { useAnalysisReport } from "../../../hooks/useAnalysisReport";
import CareerAndPersonalities from ".././analysis/CareerAndPersonalities";
import Button from "../../components/Button";

import "./stridepac.css";
import ReportSummary from "./summary/reportSummary";
import UserDetailsNew from "./userDetails/userDetails";
import StrongSuit from "./strongSuit/stringSuit";
import lockImg from "../../../assets/Stride/locks.png";
import { useLocation } from "react-router-dom";

const staticData = {
  disclaimer: ` Psychometric tests are based on highly standardized methodology and scientific rigor, which makes them extremely generalizable, however, in no way does it provide an exhaustive persona around a person nor do we claim absolute reliability, the test has its limitations to define a broad concept like personality. 
  The results, thus, obtained are for an overall understanding of the self to facilitate better decision-making. For increasing the validity and further consideration of dimensional factors, a mentor consultation for interpreting the assessment and specifying career pathways is recommended.`,
};

const BannerComp = ({ bookingLink }) => {
  const redirectToBooking = () => {
    window.open(`${bookingLink}`, "_blank");
  };
  return (
    <BannerCompCont>
      <BannerImgComp src={lockImg} />
      <BannerContContent>
        <BannerText>
          You’ve unlocked a <b style={{ color: "#EFCF1C" }}>1 on 1 session</b>{" "}
          with our mentor
        </BannerText>
        <StyledButton onClick={() => redirectToBooking()}>
          Book a session Now
        </StyledButton>
      </BannerContContent>
    </BannerCompCont>
  );
};

const StridePcaDefault = ({
  reportData2,
  testId,
  isFetched,
  isSuccess2,
  isLoading2,
}) => {

  console.log("testBNamne", testId);

  const search = useLocation().search;
  //const flow = new URLSearchParams(search).get("flow");
  const userId = new URLSearchParams(search).get("user_id");
  const reportID = new URLSearchParams(search).get("report_id");

  const { token } = useParams<{ token: string }>();

  const customLogo = "https://i.ibb.co/YBZktc8/Logo-WBG.png";

  const [auth, setAuth] = useState<boolean>(false);

  useEffect(() => {
    if (!reportData2) {
      setAuth(true);
    }
  }, [reportData2]);
  const {
    data: reportMeta,
    isLoading,
  } = useAnalysisReport(userId, reportID, 5, auth);

  console.log('reportmnateasd' , reportMeta)

  const reportData = reportData2 ? reportData2 : reportMeta?.personality_resp.data;

  const [readMore, setReadMore] = useState(false);

  const isSuccess = true;
  return (
    <Container
      column
      style={{ backgroundColor: reportData2 ? "transparent" : "#F4F7FC" }}
    >
      {!isLoading2 || !isLoading ? (
        isSuccess2 || isSuccess ? (
          reportData ? (
            <React.Fragment>
              {reportData2 ? null : (
                <Navbar
                  homepage="https://strideahead.in"
                  customLogo={customLogo}
                />
              )}
              <Content className="container p-0" column>
                {reportData2 ? null : (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setReadMore(!readMore)}
                    className="disclaimer_container"
                  >
                    <h1>Disclaimer :</h1>
                    <p>
                      {!readMore
                        ? staticData?.disclaimer.length > 250
                          ? staticData?.disclaimer.slice(0, 250)
                          : staticData?.disclaimer
                        : staticData?.disclaimer}
                      {!readMore && staticData?.disclaimer.length > 250 && (
                        <span style={{ color: "blue", cursor: "pointer" }}>
                          {" "}
                          ...Read More
                        </span>
                      )}
                    </p>
                  </div>
                )}
                <DetailAndSummaryContainer>
                  <ReportSummary
                    testId={testId}
                    test2={""}
                    data={reportMeta}
                    authenticate={true}
                    reportMeta={reportData}
                  />
                  <UserDetailsNew
                    reportMeta={reportData}
                    testId={testId}
                    test2={""}
                    data={reportMeta}
                    authenticate={true}
                  />
                </DetailAndSummaryContainer>
                {reportData2 ? null : (
                  <BannerComp
                    bookingLink={
                       REPORT_PAGE_BOOKING_LINK
                    }
                  />
                )}
                <StrongSuit reportMeta={reportData} />
                <Traits traitMap={reportData["trait-map-content"]} />
                <div className="disclaimer_container">
                  <h1>Note :</h1>
                  <p>
                    Personality is just one of the factors to consider while
                    choosing a career path. Other factors like aptitude,
                    emotional quotient, interest, and work style and ability are
                    also equally important to determine the profession best
                    suited for us.{" "}
                    <b>
                      Kindly contact the team for your complete career
                      assesment.
                    </b>
                  </p>
                </div>
                {reportData2 ? null : (
                  <CareerAndPersonalities
                    reportMetadata={reportData}
                  />
                )}
                {/*{reportData2 ? null : userId == "app" ? (
                  <></>
                ) : (
                  <TestDetails testId={testId} />
                )}*/}
              </Content>
              {reportData2 ? null : <Footer />}
            </React.Fragment>
          ) : // null)
          isFetched ? (
            <Loading />
          ) : null
        ) : (
          <ErrorFallback
            error="500"
            showNavbar={false}
            url={`/test/`}
          />
        )
      ) : // null)}
      isFetched ? (
        <Loading />
      ) : null}
    </Container>
  );
};

export default StridePcaDefault;

const Container = styled(Flex)`
  width: 100%;
  background: ${({ theme }) => theme.colors.blue[50]};
  font-family: Poppins;
  @media only screen and (max-width: 1024px) {
    background: ${({ theme }) => theme.colors.grey[50]};
    padding: 1.2rem;
  }
`;

const BannerCompCont = styled(Flex)`
  position: relative;
  width: 100%;
  height: 192px;
  background: #1f6da3;
  margin-top: 60px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const BannerContContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-left: auto;
  justify-content: space-around;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    margin-right: auto;
    width: 90%;
  }
`;

const BannerImgComp = styled.img`
  position: absolute;
  bottom: -160px;
  height: 500px;
  width: 500px;
  object-fit: contain;
  left: -100px;
  @media only screen and (max-width: 800px) {
    opacity: 0.1;
    bottom: -180px;
    left: 0px;

    object-fit: cover;
  }
`;

const BannerText = styled.h1`
	font-family: Poppins;
	font-size: 30px;
	font-weight: medium;
	z-index: 2;
	width: 50%;
	/* margin-left: auto;
	margin-right: auto; */
	/ text-align: center; /

	color: #fff;
	@media only screen and (max-width: 800px) {
		width: 90%;
		font-size: 24px;
		text-align: center;
	}
`;

const Content = styled(Flex)`
  width: 100%;
  margin: 1.5rem auto;
  margin-top: 6rem;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const DetailAndSummaryContainer = styled(Flex)`
  width: 100%;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blue[500]};
  width: max-content;
  padding: 1.6rem 4.6rem;

  @media only screen and (max-width: 1024px) {
    margin: auto;
    margin-top: 10px;
    z-index: 2;
    padding: 0rem 0rem;
    width: 100%;
    height: 50px;
    margin-top: 20px;
  }
`;
