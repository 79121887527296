import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Flex from '../../components/Flex';
import rightIcon from '../../../assets/images/rightIcon.png';
import Button from '../../components/Button';
function CareerComp(props) {
	const getWidth = () => {
		const { innerWidth: width } = window;
		return width;
	};

	useEffect(() => {
		window.addEventListener('resize', getWidth);
		setWindowWidth(getWidth());
	}, []);

	const redirectToBooking = (link) => {
		window.open(link, '_blank');
	};

	const [windowWidth, setWindowWidth] = useState(getWidth());

	return (
		<Cont>
			<ImageContainer onClick={props.onClick}>
			<div
			style={{
				backgroundColor:  '#1273B7',
				borderRadius: "50%",
				height: "60px",
				width: "60px",
				position: "relative",
				margin: "0 2.4rem"
			}}>
				<img src={props.profession.image}
				style={{
					height: "50%",
					width: "50%",
					position: "absolute",
					margin: "auto",
					top: "0",
					left: "0",
					right: "0",
					bottom: "0",
				}} 
				alt={''} />
			</div>

					<span
					style={{ color: props.active == props.index ? 'black' : '#1273B7' }}
				>
					{props.profession.name}
				</span>
				{windowWidth > 768 && props.profession.link !== '' ? (
					<p
						style={{ marginTop: 6 }}
						data-tooltip='Know More'
						className='section-tooltip'
					>
						<ImageArrow
							onClick={() => redirectToBooking(props.profession.link)}
							src={rightIcon}
						/>
					</p>
				) : props.profession.link !== '' ? (
					<StyledButton
						onClick={() => redirectToBooking(props.profession.link)}
					>
						Know More
					</StyledButton>
				) : null}
			</ImageContainer>

			<JobRoles>
				{props.profession.job_roles?.map((role, index) => (
					<JobCont>
						<span
							style={{
								position: 'absolute',
								height: 10,
								width: 10,
								borderRadius: 10,
								marginLeft: 5,
								backgroundColor: 'black',
							}}
						/>
						<p
							style={{
								marginLeft: 25,
								width: '100%',
							}}
						>
							{role?.name}
						</p>
					</JobCont>
				))}
			</JobRoles>
		</Cont>
	);
}

export default CareerComp;

const JobRoles = styled(Flex)`
flex-direction: column;

padding: 1rem 4rem 0 2rem;

 p{
  margin:0 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
 };
hr {
  opacity:1;
  height:60%;
  width:1px;
  margin:0 2rem;
  background-color:#222;
};
  img{
    width: 36px;
    height 36px;
    object-fit: cover;
    border-radius: 100%;
  }

  @media screen and (max-width: 1024px) {

    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    div {
      width:100%;
      display:flex;  
      align-items:center;
      justigy-content:flex-start;
    }
 }

`;

const CareerAndPersonalitiesContainer = styled(Flex)`
	font-family: Roboto;
	flex: 1;
	width: 100%;
	background: ${({ theme }) => theme.colors.white};
	padding: 3.2rem 5.3rem;
	border-radius: 10px;
	@media only screen and (max-width: 1024px) {
		padding: 1rem;
	}
`;

const CareerContainer = styled(CareerAndPersonalitiesContainer)`
	display: flex;
	padding: 0;
	margin-left: 0;
	color: ${({ theme }) => theme.colors.blue[500]};
	flex-direction: row;

	flex-wrap: wrap;

	div {
		padding-bottom: 0.5rem;
	}
	img {
		margin: 0 2.4rem;
	}

	@media screen and (max-width: 1024px) {
		flex-direction: column;
		margin: 0;
		padding: 0;
		font-size: 1.4rem;
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;
		margin: 0;
		padding: 0;
		font-size: 1.4rem;
		div {
			padding-bottom: 0;
		}
	}
`;

const ImageContainer = styled(Flex)`
	width: 100%;
	height: 100px;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	margin-right: 2rem;
	color: ${({ theme }) => theme.colors.text.black};
	background: rgba(18, 115, 183, 0.12);
	border-radius: 4px;

	hr {
		margin: 0;
		width: 2px;
		opacity: 1;
		height: 100%;
	}

	span {
		margin: auto;
		font-weight: 500;
		font-size: 1.6rem;
		line-height: 2rem;
		text-align: center;
		margin-left: 1rem;
	}

	@media only screen and (max-width: 1024px) {
		/* flex: 1 1 40%; */
		/* margin: 0.8rem 1.2rem 0.8rem 0; */
		flex-direction: row;
		align-items: center;
		color: ${({ theme }) => theme.colors.blue[500]};
		margin-right: 0px;
		img {
			height: 5rem;
			width: 5rem;
			/* margin: 1rem; */
		}
		span {
			font-size: 1.6rem;
			margin-left: 1.6rem;
		}
	}

	@media only screen and (max-width: 768px) {
		height: 70px !important;
		min-height: 70px;
		width: 100%;

		justify-content: space-between;
		align-items: space-between;
		span {
			font-size: 1.6rem;

			text-align: left;
			width: 40%;
			margin-left: 0px;
		}
	}
`;

const ArrowComp = styled.div`
	cursor: pointer;
	.tooltip {
		display: none;
	}
	&:hover {
		.tooltip {
			display: flex;
			background-color: red;
			
		}
	}
`;

const ImageArrow = styled.img`
		height: 18px;
		width: 18px`

// const ToolTip = styled.div`
// 	height: 50px;
// 	width: 50px;
// 	/* background-color: red; */

// 	${ImageArrow}:hover & {
// 		background-color: #290909;
// 		height: 100px;
// 		width: 100px;
// 	}
// `;

const Cont = styled.div`
	display: flex;
	flex-direction: column;
	margin: 14px;
	width: 30%;
	border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 7px;
	background-color: ${({ theme }) => theme.colors.white};
	position: relative;
	min-height: 250px;
	/* height: 400px; */
	@media only screen and (max-width: 768px) {
		width: 100%;
		/* height: 50px; */
		min-height: 30px;
		margin-left: 0px;
		margin-right: 0px;
	}
`;

const JobCont = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	margin: 5px;
	color: ${({ theme }) => theme.colors.text.black};
`;

const StyledButton = styled(Button)`
	background-color: ${(props) => props.theme.colors.blue[500]};
	color: ${(props) => props.theme.colors.white};  
	width: max-content;
	padding: 4px 8px;
	font-size: 12px;
	width: 100px;
	height: 30px;
	border-radius: 2px;
	margin-right: 10px;
	@media only screen and (max-width: 400px) {
		/* margin: auto;
		margin-top: 10px;
		z-index: 2;
		padding: 0rem 0rem;
		width: 70px;
		margin-top: 20px; */
		width: 80px;
		font-size: 8px;
	}
`;
