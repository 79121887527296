import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
//import { API_ENDPOINT } from '../utils/constants';
import { APIError, ReportMetadataResponse } from "../utils/types";

export const useAnalysisReport = (
  token: any,
  pdf: any = false,
  refetchIntervalInSeconds: number = 0,
  enabled: boolean = true
) => {
  const fetchAnalysis = async (token: any, pdf) => {
    let url = `https://forms-backend-production.strideahead.io/v1/quiz-backend/report/?id=${token}&client=stride`;
    try {
      const { data: data } = await axios.get(url, {
        withCredentials: true,
      });
      console.log("data fro api", data);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  };

  return useQuery<ReportMetadataResponse, AxiosResponse<APIError>>(
    ["analysis", token, pdf, enabled],
    () => fetchAnalysis(token, pdf),
    {
      retry: false,
      enabled: enabled,
    }
  );
};