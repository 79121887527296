// @ts-nocheck
import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useCurrentProfile } from "../../hooks/useProfile";
import Dashboard from "./Dashboard";

const AuthenticatedApp = () => {
  const { isLoading, isError } = useCurrentProfile();
  const history = useHistory();

  console.log("isError", isError);

  const renderAppComponent = () => {
    if (isLoading) {
      return <div>App Loading...</div>;
    } else if (isError) {
      history.push("/login");
    } else {
      return (
        <Switch>
          <Route path="/" component={Dashboard} />
        </Switch>
      );
    }
  };

  return <>{renderAppComponent()}</>;
};

export default AuthenticatedApp;
