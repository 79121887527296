import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import useTest from "../../hooks/useTest";
import StridePcaDefault from "./report_pages/StridePcaDefault";
import AskIITiansTestPage from "./report_pages/AskIITiansReportPage";
import DwecStrideDefault from "./report_pages/DwecStrideDefault";
import AmuziStrideDefault from "./report_pages/AmuziStrideDefault";
import AptitudeReportPage from "./report_pages/AptitudeReportPage";
import AptitudeTestReport from "./report_pages/AptitudeTestReportPage";
import EmployabilityReportPage from "./report_pages/EmployabilityReportPage";
import Loading from "../components/Loading";
import ManagmentReport from "./report_pages/ManagmentReport";
import DimensionsTestReport from "./report_pages/DimensionsTestReport";
import DimensionsTestReport2 from "./report_pages/DimensionsTestReport2";
import RiasecTestReport from "./report_pages/RiasecTestReport";
import WeAceReportPage from "./report_pages/WeAceReportPage";
import AskiitiansHackathonReport from "./AskiitiansHackathonReport";
import RedutechReportPage from "./report_pages/RedutechReportPage";
import RedutechReportPage2 from "./report_pages/RedutechReportPage2";
import GrowthCircleRiasecTestReport from "./report_pages/GrowthCircleRiasecTestReport";

const AnalysisReport = () => {
  const { testName } = useParams<{ testName: string }>();
  const testId = "";
  const { isFetched, data: test } = useTest(testId);
  const [component, setComponent] = useState<any>();
  //const isFetched = true;

  useEffect(() => {
    switch (testName) {
      case "personality":
        setComponent(
          <StridePcaDefault
            isFetched={isFetched}
            testId={""}
            reportData2={undefined}
            isSuccess2={undefined}
            isLoading2={undefined}
          />
        );
        break;
      //case "Brainwave Intelligence Report Page":
      //  setComponent(
      //    <AskiitiansHackathonReport
      //      isFetched={isFetched}
      //      testId={testId}
      //      test={""}
      //    />
      //  );
      //  break;

      //case "DWEC PCA Default":
      //  setComponent(
      //    <DwecStrideDefault
      //      isFetched={isFetched}
      //      testId={testId}
      //      test={""}
      //    />
      //  );
      //  break;

      //case "Amuzi PCA Default":
      //  setComponent(
      //    <AmuziStrideDefault
      //      isFetched={isFetched}
      //      testId={testId}
      //      test={""}
      //    />
      //  );
      //  break;

      //case "Askiitians Report Page":
      //  setComponent(
      //    <AskIITiansTestPage
      //      isFetched={isFetched}
      //      testId={testId}
      //      test={""}
      //    />
      //  );
      //  break;

      //case "Aptitude Report Page":
      //  setComponent(
      //    <AptitudeReportPage
      //      isFetched={isFetched}
      //      testId={testId}
      //      test={""}
      //    />
      //  );
      //  break;
      //case "Aptitude Assessment Report Page":
      //  setComponent(
      //    <AptitudeTestReport
      //      isFetched={isFetched}
      //      testId={testId}
      //      test={""}
      //    />
      //  );
      //  break;
      //case "Employability Report Page":
      //  setComponent(
      //    <EmployabilityReportPage
      //      isFetched={isFetched}
      //      testId={testId}
      //      test={""}
      //    />
      //  );
      //  break;
      //case "Management 101 Report Page":
      //  setComponent(
      //    <ManagmentReport
      //      isFetched={isFetched}
      //      testId={testId}
      //      test={""}
      //    />
      //  );
      //  break;
      //case "Dimensions Report Page":
      //  setComponent(
      //    <DimensionsTestReport2
      //      isFetched={isFetched}
      //      testId={testId}
      //      test={""}
      //    />
      //  );
      //  break;

      //case "Growth Circle Riasec Report Page":
      //  setComponent(
      //    <GrowthCircleRiasecTestReport
      //      isFetched={isFetched}
      //      testId={testId}
      //      test={""}
      //    />
      //  );
      //  break;

      //case "Riasec Report Page":
      //  setComponent(
      //    <RiasecTestReport
      //      isFetched={isFetched}
      //      testId={testId}
      //      test={""}
      //    />
      //  );
      //  break;

      //case "WeAce Report Page":
      //  setComponent(
      //    <WeAceReportPage
      //      isFetched={isFetched}
      //      testId={testId}
      //      test={""}
      //    />
      //  );
      //  break;

      //case "RailEdutech Assessment Report Page":
      //  setComponent(
      //    <RedutechReportPage
      //      test={""}
      //      testId={testId}
      //      isFetched={isFetched}
      //    />
      //  );

      //  break;

      //case "RailEdutech Indepth Assessment Report Page":
      //  setComponent(
      //    <RedutechReportPage2
      //      test={""}
      //      testId={testId}
      //      isFetched={isFetched}
      //    />
      //  );

      //break;
    }
  }, [testName]);

  return <React.Fragment>{testName && component}</React.Fragment>;
};

export default AnalysisReport;
