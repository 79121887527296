import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useTest from '../../hooks/useTest';
import AmuziStrideDefault from "./test_pages/AmuziStrideDefault";
import AptitudeTestPage from "./test_pages/AptitudeTestPage";
import DwecStrideDefault from "./test_pages/DwecStrideDefault";
import EmployabilityTestPage from "./test_pages/EmployabilityTestPage";
import SingleQuestionPage from './test_pages/SingleQuestionTestPage';
import StridePcaDefault from "./test_pages/StridePcaDefault";
import WeAceTestPage from "./test_pages/WeAceTestPage";
import RankingQuestionTestPage from './test_pages/RankingQuestionTestPage';

const TestScreen = ({location}) => {
    const {testId} = useParams<{ testId: string }>(); 
    const {isLoading, data: test} = useTest(testId);
    const [component, setComponent] = useState<any>();
    const loadingImage = require(`../../assets/Loaders/WeAceLoader.gif`)?.default;
    useEffect(() => {
        switch (test?.test_page?.name) {
            //case "Stride PCA Default" :
            //    setComponent(<StridePcaDefault  testId={testId} test={test}/>);
            //    break;

            case "DWEC PCA Default" :
                setComponent(<DwecStrideDefault  testId={testId} test={test}/>);
                break;

            case "Amuzi PCA Default" :
                setComponent(<AmuziStrideDefault  testId={testId} test={test}/>);
                break;

            case "Default Aptitude Test Page" :
                default:
                setComponent(<AptitudeTestPage  testId={testId} test={test}/>);
                break;

            case "Stride Cohort Default" :
                setComponent(<AptitudeTestPage  testId={testId} test={test}/>);
                break;

            case "WeAce Default Test Page":
                setComponent(<WeAceTestPage testId={testId} test={test}/>);
                break;

            case "WeAce Raking Test Page":
                    setComponent(<RankingQuestionTestPage testId={testId} test={test}/>)
                    break;

            case "Default Employability Test Page" :
              setComponent(<EmployabilityTestPage  testId={testId} test={test}/>);
              break;

            case "Default Single Question Test Page":
                setComponent(<SingleQuestionPage testId={testId} test={test}/>)
        }
    }, [test]);

    return (

        <React.Fragment>
            {component}
        </React.Fragment>
    );
};

export default TestScreen;
