import React, { FC } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import StrideAheadLogo from "../../assets/stride_ahead_logo.svg";
import { useCurrentProfile } from "../../hooks/useProfile";
import useTest from "../../hooks/useTest";
import { PROCESSOR_S3_URL } from "../../utils/constants";
import Avatar from "./Avatar";
import Flex from "./Flex";

const Navbar: FC<{
  homepage?: string;
  customLogo?: string;
  authenticate?: boolean;
}> = ({ homepage = "/", customLogo, authenticate = true }) => {
  const { testId } = useParams<{ testId: string }>();
  const { isLoading, data: test } = useTest(testId);
  const { isSuccess } = useCurrentProfile(!isLoading && authenticate);
  const logoPath = `${PROCESSOR_S3_URL}${customLogo}`;

  return (
    <Container>
      <LogoContainer justify="center" align="center">
        {!customLogo && (
          <a href={homepage}>
            <img src={StrideAheadLogo} alt="Stride Ahead Logo" />
          </a>
        )}
        {customLogo && <img src={customLogo} alt="logo" />}
      </LogoContainer>
      {isSuccess && authenticate && <Avatar />}
    </Container>
  );
};

export default Navbar;

const Container = styled(Flex)`
  width: 100%;
  height: 6rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 1px 1rem ${({ theme }) => theme.colors.grey[100]};
  z-index: 99;
`;

const LogoContainer = styled(Flex)`
  height: 100%;
  width: 26rem;

  @media only screen and (max-width: 1024px) {
    justify-content: left;
    padding-left: 1.3rem;
  }
`;
