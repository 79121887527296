import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { API_ENDPOINT } from "../utils/constants";
import { APIError, CurrentUserProfile } from "../utils/types";

export const useCurrentProfile = (enabled: boolean = true, headers?: any) => {
  const fetchCurrentProfile = async (headers) => {
    try {
      const currentUrlParams = new URLSearchParams(window.location.search);
      console.log("headers", headers);
      console.log("Cookies:", document.cookie);

      let refresh_token = currentUrlParams.get("refresh_token");
      console.log("1st log", refresh_token);

      const {
        data: { data },
      } = await axios.get(API_ENDPOINT + "me", {
        headers: { refresh_token: refresh_token || "" },
      });
      console.log("data", data);
      return data;
    } catch (error) {
      throw error.response && error.response.data;
    }
  };

  return useQuery<CurrentUserProfile, AxiosResponse<APIError>>(
    ["profile", "current", headers],
    fetchCurrentProfile,
    {
      retry: false,
      enabled,
    }
  );
};
